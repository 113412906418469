/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Select,
  TextField,
  FormControl,
  MenuItem,
  Divider,
  Autocomplete,
  InputLabel,
  Grid,
  InputAdornment,
} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { useSnackbar } from "notistack";
import {
  add_Template,
  add_template_page_by_slugs,
  get_category_detail,
  upload_image,
  _add_page_slug_in_template,
  add_payment_page_id_against_template,
} from "../../../DAL/templates/templates";
import "./addtemplate.css";
import Checkbox from "@material-ui/core/Checkbox";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import { upload_folder } from "../../../DAL/section/section";
import { TwitterPicker } from "react-color";

import {
  color_array,
  options,
  font_weight,
  line_Height,
  template_type,
  google_font_family_classes,
  project_type,
  is_Paid,
  currency_options,
} from "../../../ui_libs/constants";
import { template_category_list } from "../../../DAL/template_categories/TemplateCategories";
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  default_Theme_btn: {
    paddingTop: 0,
    paddingBottom: 0,
    // marginBottom: "10px",
  },
  "MuiFormControl-marginNormal": {
    marginBottom: 0,
    marginTop: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 160,
    width: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modemenu: {},
}));

export default function AddTemplates(props) {
  const classes = useStyles();
  const history = useHistory();
  const [inputs, setInputs] = useState({
    categorydata: [],
    category: [],
    Image: "",
    file: "",
    name: "",
    mode: "",
    primarybackground: "",
    secondarybackground: "",
    primarytextcolour: "",
    secondarytextcolour: "",
    hoverTextColor: "",
    hoverBackgroundColor: "",
    textFontFamily: "",
    themeBoxShadow: "",
    themeH1FontSize: "",
    themeH2FontSize: "",
    themeH3FontSize: "",
    themeH4FontSize: "",
    themePFontSize: "",
    themeH1FontWeight: "",
    themeH2FontWeight: "",
    themeH3FontWeight: "",
    themePFontWeight: "",
    folderarr: [],
    _folder: "",
    folder: "",
    status: true,
    completed: true,
    approved: true,
    published: true,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [categorydata, setCategorydata] = React.useState([]); //ok
  const [templateCategorydata, setTemplateCategorydata] = React.useState([]); //ok
  const [templateCategory, setTemplateCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [mode, setMode] = useState("light");
  const [primarybackground, setPrimaryBackground] = useState("");
  const [secondarybackground, setSecondaryBackground] = useState("");
  const [textHeadingcolour, setTextHeadingColour] = useState("");
  const [primarytextcolour, setPrimaryTextColour] = useState("");
  const [secondarytextcolour, setSecondaryTextColour] = useState("");
  const [hoverTextColor, sethoverTextColor] = useState("");
  const [hoverBackgroundColor, sethoverBackgroundColor] = useState("");
  const [themeBoxShadow, setthemeBoxShadow] = useState("");
  const [menuColour, setMenuColour] = useState("");
  const [menuHoverColour, setMenuHoverColour] = useState("");
  const [inputTextColour, setInputTextColour] = useState("");
  const [inputPlaceholderColour, setInputPlaceholderColour] = useState("");
  const [inputBackGroudColour, setinputBackgroundColour] = useState("");
  const [inputBorderColour, setinputBorderColour] = useState("");

  const [textFontFamily, settextFontFamily] = useState("");
  const [textH1FontFamily, settextH1FontFamily] = useState("");
  const [textH2FontFamily, settextH2FontFamily] = useState("");
  const [textH3FontFamily, settextH3FontFamily] = useState("");
  const [textH4FontFamily, settextH4FontFamily] = useState("");

  const [themeH1FontSize, setthemeH1FontSize] = useState("");
  const [themeH2FontSize, setthemeH2FontSize] = useState("");
  const [themeH3FontSize, setthemeH3FontSize] = useState("");
  const [themeH4FontSize, setthemeH4FontSize] = useState("");
  const [themePFontSize, setthemePFontSize] = useState("");
  const [themeLineHeight, setthemeLineHeight] = useState("1.5");
  const [themeH1FontWeight, setthemeH1FontWeight] = useState("");
  const [themeH2FontWeight, setthemeH2FontWeight] = useState("");
  const [themeH3FontWeight, setthemeH3FontWeight] = useState("");
  const [themeH4FontWeight, setthemeH4FontWeight] = useState("");
  const [templateType, setTemplateType] = useState("0");
  const [projectType, setProjectType] = useState("0");
  const [themePFontWeight, setthemePFontWeight] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [discount, setDiscount] = useState();
  const [taxPercentAge, setTaxPercentAge] = useState();
  const [ribbon, setRibbon] = useState("");
  const [discountError, setDiscountError] = useState(false);

  const theme_colors_array = [
    {
      label: "--wb-website-theme-primary-color",
      handleClick: (value) => setPrimaryBackground(value),
    },
    {
      label: "--wb-website-theme-secondary-color",
      handleClick: (value) => setSecondaryBackground(value),
    },
    {
      label: "--wb-website-hover-background-color",
      handleClick: (value) => sethoverBackgroundColor(value),
    },
    {
      label: "--wb-website-text-heading_color",
      handleClick: (value) => setTextHeadingColour(value),
    },
    {
      label: "--wb-website-text-primary-color",
      handleClick: (value) => setPrimaryTextColour(value),
    },
    {
      label: "--wb-website-text-secondary-color",
      handleClick: (value) => setSecondaryTextColour(value),
    },
    {
      label: "--wb-website-hover-text-color",
      handleClick: (value) => sethoverTextColor(value),
    },
    {
      label: "--wb-theme-box-shadow",
      handleClick: (value) => setthemeBoxShadow(value),
    },
    {
      label: "--wb-input-text-color",
      handleClick: (value) => setInputTextColour(value),
    },
    {
      label: "--wb-input-placeholder-color",
      handleClick: (value) => setInputPlaceholderColour(value),
    },
    {
      label: "--wb-input-background-color",
      handleClick: (value) => setinputBackgroundColour(value),
    },
    {
      label: "--wb-input-border-color",
      handleClick: (value) => setinputBorderColour(value),
    },
    {
      label: "--wb-menu-color",
      handleClick: (value) => setMenuColour(value),
    },
    {
      label: "--wb-menu-hover-color",
      handleClick: (value) => setMenuHoverColour(value),
    },
  ];

  const theme_fonts_array = [
    {
      label: "--wb-website-text-font-family",
      handleClick: (value) => settextFontFamily(value),
    },
    {
      label: "--wb-theme-h1-font-family",
      handleClick: (value) => settextH1FontFamily(value),
    },
    {
      label: "--wb-theme-h2-font-family",
      handleClick: (value) => settextH2FontFamily(value),
    },
    {
      label: "--wb-theme-h3-font-family",
      handleClick: (value) => settextH3FontFamily(value),
    },
    {
      label: "--wb-theme-h4-font-family",
      handleClick: (value) => settextH4FontFamily(value),
    },
    {
      label: "--wb-theme-h1-font-size",
      handleClick: (value) => setthemeH1FontSize(value),
    },
    {
      label: "--wb-theme-h2-font-size",
      handleClick: (value) => setthemeH2FontSize(value),
    },
    {
      label: "--wb-theme-h3-font-size",
      handleClick: (value) => setthemeH3FontSize(value),
    },
    {
      label: "--wb-theme-h4-font-size",
      handleClick: (value) => setthemeH4FontSize(value),
    },
    {
      label: "--wb-theme-p-font-size",
      handleClick: (value) => setthemePFontSize(value),
    },
    {
      label: "--wb-theme-h1-font-weight",
      handleClick: (value) => setthemeH1FontWeight(value),
    },
    {
      label: "--wb-theme-h2-font-weight",
      handleClick: (value) => setthemeH2FontWeight(value),
    },
    {
      label: "--wb-theme-h3-font-weight",
      handleClick: (value) => setthemeH3FontWeight(value),
    },
    {
      label: "--wb-theme-h4-font-weight",
      handleClick: (value) => setthemeH4FontWeight(value),
    },
    {
      label: "--wb-theme-p-font-weight",
      handleClick: (value) => setthemePFontWeight(value),
    },
    {
      label: "--wb-theme-line-height",
      handleClick: (value) => setthemeLineHeight(value),
    },
  ];

  const [folderarr, setFolderArr] = React.useState([]);
  const [folder, setFolder] = React.useState("");
  const [_folder, set_Folder] = React.useState("");

  const [state, setState] = React.useState({
    status: true,
    completed: true,
    approved: true,
    published: true,
  });

  const [checked, setChecked] = React.useState(false);
  const [HTMLFiles, setHTMLFiles] = useState([]);
  const [paymentPage, setPaymentPage] = useState("-1");

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  const folderChangedHandler = (e) => {
    console.log(Object.values(e.target.files), "guko");
    console.log(e.target.files);

    setFolderArr(Object.values(e.target.files));
    let files = [];
    Object.values(e.target.files).map((x, i) => {
      if (x.name.includes(".html")) {
        files.push(x.name);
        if (x) {
          const reader = new FileReader();

          reader.onload = (event) => {
            const content = event.target.result;
            setHtmlContent(content);
          };

          reader.readAsText(x);
        }
      }
    });
    setHTMLFiles([...files]);
    if (e.target.files.length) {
      set_Folder(e.target.files);
      setFolder(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChange = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleChangeApproved = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeCategory = (value) => {
    console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      if (x._id) {
        _catTag.push({ category_id: x._id });
      } else {
        _catTag.push({ category_id: x.template_category_id });
      }
    });
    setCategory(_catTag);
    console.log(_catTag, "final tags");
  };

  const handleChangeTemplateCategory = (value) => {
    console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      if (x._id) {
        _catTag.push({ category_id: x._id });
      } else {
        _catTag.push({ category_id: x.template_category_id });
      }
    });
    setCategory(_catTag);
    console.log(_catTag, "final tags");
  };

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "category");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp);
    setimage(upload_image_resp.file_name);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
    }
    // console.log(e.target.files[0])
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();

    if (isPaid && amount == 0) {
      enqueueSnackbar("Amount should be greater than 0.", { variant: "error" });
      return;
    }

    if (isPaid && amount <= discount) {
      enqueueSnackbar("Discount should be less than amount.", {
        variant: "error",
      });
      return;
    }

    if (projectType == 1 && paymentPage == -1) {
      enqueueSnackbar("please Select Payment Page", { variant: "error" });
      return;
    }
    console.log(Image);
    if (!Image) {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    } else {
      setiswaiting(true);
      let postdata = {
        name: name,
        mode: mode,
        category: category,
        template_category: templateCategory,
        theme_primary_color: primarybackground,
        theme_secondary_color: secondarybackground,
        text_primary_color: primarytextcolour,
        text_secondary_color: secondarytextcolour,
        hover_text_color: hoverTextColor,
        hover_background_color: hoverBackgroundColor,
        theme_box_shadow: themeBoxShadow,
        theme_heading_color: textHeadingcolour,
        menu_color: menuColour,
        menu_hover_color: menuHoverColour,
        input_text_color: inputTextColour,
        input_placeholder_color: inputPlaceholderColour,
        input_background_color: inputBackGroudColour,
        input_border_color: inputBorderColour,

        text_font_family: textFontFamily,
        theme_h1_font_size: themeH1FontSize.toString(),
        theme_h2_font_size: themeH2FontSize.toString(),
        theme_h3_font_size: themeH3FontSize.toString(),
        theme_h4_font_size: themeH4FontSize.toString(),
        theme_p_font_size: themePFontSize.toString(),
        theme_h1_font_weight: themeH1FontWeight.toString(),
        theme_h2_font_weight: themeH2FontWeight.toString(),
        theme_h3_font_weight: themeH3FontWeight.toString(),
        theme_h4_font_weight: themeH4FontWeight.toString(),
        theme_p_font_weight: themePFontWeight.toString(),
        theme_h1_font_family: textH1FontFamily.toString(),
        theme_h2_font_family: textH2FontFamily.toString(),
        theme_h3_font_family: textH3FontFamily.toString(),
        theme_h4_font_family: textH4FontFamily.toString(),
        theme_line_height: themeLineHeight.toString(),

        is_approved: state.approved,
        is_completed: state.completed,
        is_published: state.published,
        // template_type: templateType,
        image: Image,
        status: state.status,
        app_type: templateType,
        type: projectType,
        is_paid: isPaid,
        amount: isPaid ? parseFloat(amount.toFixed(2)) : "",
        currency: isPaid ? currency : "",
        discount: isPaid ? discount : "",
        ribbon: isPaid ? ribbon : "",
        tax_percentage: isPaid ? taxPercentAge : "",
      };
      console.log(postdata, "data to be send");

      const template_add_resp = await add_Template(postdata);
      if (template_add_resp.code == 200) {
        //props.history.push("/templates");
        //  props.history.push("Templates");
        console.log(template_add_resp, "template response");
        console.log(folderarr);
        const promises = [];
        const slug_list = [];
        folderarr.map((file, i) => {
          if (file.name.includes(".html")) {
            let name = file.name;
            if (name.includes(".html")) {
              slug_list.push(name.split(".")[0]);
            }
            let formData = new FormData(); //formdata object
            formData.append("project_name", template_add_resp.template._id);
            formData.append("upload_file", file);
            formData.append("content_type", file.type);

            const upload_folder_response = upload_folder(formData);
            console.log(upload_folder_response);
            promises.push(upload_folder_response);
          } else if (file.name.includes(".js")) {
            console.log("index.js");
            let formData = new FormData(); //formdata object
            formData.append(
              "project_name",
              template_add_resp.template._id + "/js"
            );
            formData.append("upload_file", file);
            formData.append("content_type", file.type);
            const upload_folder_res = upload_folder(formData);
            console.log(upload_folder_res);
            promises.push(upload_folder_res);
          } else if (file.name.includes(".css")) {
            let formData = new FormData(); //formdata object
            formData.append(
              "project_name",
              template_add_resp.template._id + "/css"
            );
            formData.append("upload_file", file);
            formData.append("content_type", file.type);
            const upload_folder_res = upload_folder(formData);
            console.log(upload_folder_res);
            promises.push(upload_folder_res);
          } else {
            let formData = new FormData(); //formdata object
            formData.append(
              "project_name",
              template_add_resp.template._id + "/assets"
            );
            formData.append("upload_file", file);
            formData.append(
              "content_type",
              file.type ? file.type : "image/png"
            );
            const upload_folder_resp = upload_folder(formData);
            console.log(upload_folder_resp);
            promises.push(upload_folder_resp);
          }
        });

        let postData = {
          page_slug_array: slug_list,
        };
        const edit_template_resp = _add_page_slug_in_template(
          template_add_resp.template._id,
          postData
        );
        promises.push(edit_template_resp);

        await Promise.all(promises)
          .then(async (res) => {
            if (res.length !== 0) {
              const result = await add_template_page_by_slugs(
                template_add_resp.template._id
              );

              if (result.code == 200) {
                if (projectType == 1) {
                  let paymentPageID = "";
                  result.page_list.map((page) => {
                    if (paymentPage == page.page_slug) {
                      paymentPageID = page._id;
                    }
                  });
                  const paymentPageRespone =
                    await add_payment_page_id_against_template(
                      template_add_resp.template._id,
                      { payment_page_id: paymentPageID }
                    );

                  if (paymentPageRespone.code == 200) {
                    enqueueSnackbar("Folder Uploaded", { variant: "success" });
                    props.history.push("/templates");
                  } else {
                    enqueueSnackbar(paymentPageRespone.message, {
                      variant: "error",
                    });

                    setiswaiting(false);
                  }
                } else {
                  enqueueSnackbar("Folder Uploaded", { variant: "success" });
                  props.history.push("/templates");
                }
              } else {
                enqueueSnackbar(result.message, { variant: "success" });
                setiswaiting(true);
              }
            }
          })
          .catch((err) => {
            console.log(err.message, "Error");
            enqueueSnackbar(err.message, "Folder not uploaded successfully", {
              variant: "error",
            });
          });
      } else {
        setiswaiting(false);
        enqueueSnackbar(template_add_resp.message, { variant: "error" });
      }
      // }else{
      //   enqueueSnackbar(template_add_resp.message, { variant: "error" });
      // }
    }
  };
  const defaultTheme = () => {
    setMode("light");
    setPrimaryBackground("#FFFFFF");
    setSecondaryBackground("#eb144c");
    setPrimaryTextColour("#000000");
    setSecondaryTextColour("#FFFFFF");
    sethoverTextColor("#FFFFFF");
    sethoverBackgroundColor("#eb144c");
    setTextHeadingColour("#000000");
    setMenuColour("#000000");
    setMenuHoverColour("#ffffff");
    setInputTextColour("");
    setInputPlaceholderColour("");
    setinputBorderColour("");
    setinputBackgroundColour("");
    setthemeBoxShadow("#ABB8C3");

    settextFontFamily("'Roboto',sans-serif");
    settextH1FontFamily("'Montserrat',sans-serif");
    settextH2FontFamily("'Montserrat',sans-serif");
    settextH3FontFamily("'Montserrat',sans-serif");
    settextH4FontFamily("'Montserrat',sans-serif");

    setthemeH1FontSize("2.500rem");
    setthemeH2FontSize("2.000rem");
    setthemeH3FontSize("1.750rem");
    setthemeH4FontSize("1.500rem");
    setthemePFontSize("1.000rem");
    setthemeLineHeight("1.5");
    setthemeH1FontWeight("500");
    setthemeH2FontWeight("500");
    setthemeH3FontWeight("500");
    setthemeH4FontWeight("500");
    setthemePFontWeight("500");
  };

  const fetchThemeColors = () => {
    if (htmlContent === "") {
      enqueueSnackbar("To fetch values, please upload the folder first.", {
        variant: "error",
      });
      return;
    }

    theme_colors_array.map((value) => {
      let starting_index = htmlContent.indexOf(value.label);
      let ending_index = htmlContent.indexOf(";", starting_index);

      let color_string = htmlContent.slice(starting_index, ending_index);

      let color = color_string
        .replaceAll(" ", "")
        .replace(`${value.label}:`, "");

      if (color.includes("rgb")) {
        if (color.includes("rgba")) {
          const rgbaValues = color.match(/[\d.]+/g);
          const r = parseInt(rgbaValues[0]);
          const g = parseInt(rgbaValues[1]);
          const b = parseInt(rgbaValues[2]);
          const a = parseFloat(rgbaValues[3]);

          const red = r.toString(16).padStart(2, "0");
          const green = g.toString(16).padStart(2, "0");
          const blue = b.toString(16).padStart(2, "0");

          let alphaHex = Math.round(a * 255).toString(16);
          if (alphaHex.length === 1) {
            alphaHex = "0" + alphaHex; // Ensure two digits for the hexadecimal representation
          }

          color = `#${red}${green}${blue}${alphaHex}`;
        } else {
          const [r, g, b] = color
            .replace(/[^\d,]/g, "")
            .split(",")
            .map(Number);

          const red = r.toString(16).padStart(2, "0");
          const green = g.toString(16).padStart(2, "0");
          const blue = b.toString(16).padStart(2, "0");

          color = `#${red}${green}${blue}`;
        }
      }
      value.handleClick(color);
      if (color === "" || color.length < 6) {
        enqueueSnackbar(`${value.label} did not found.`, {
          variant: "error",
        });
        console.log(`${value.label} did not found.`, "ThemeValueserror");
        return;
      }
    });
    enqueueSnackbar(`Theme Colors Added.`, {
      variant: "success",
    });
  };
  const fetchThemeFonts = () => {
    if (htmlContent === "") {
      enqueueSnackbar("To fetch values, please upload the folder first.", {
        variant: "error",
      });
      return;
    }

    theme_fonts_array.map((value) => {
      let starting_index = htmlContent.indexOf(value.label);
      let ending_index = htmlContent.indexOf(";", starting_index);

      let font_string = htmlContent.slice(starting_index, ending_index);

      let font_value = font_string.replace(`${value.label}:`, "").trimStart();

      if (font_value === "") {
        enqueueSnackbar(`${value.label} did not found.`, {
          variant: "error",
        });
        console.log(`${value.label} did not found.`, "ThemeValueserror");
        return;
      }
      console.log(font_value, "tetctecteecbeycbeybcbeubcebcecue", value.label);
      value.handleClick(font_value);
    });

    enqueueSnackbar(`Theme Font values Added.`, {
      variant: "success",
    });
  };

  useEffect(async () => {
    const category_detail = await get_category_detail(props.match.params.id);
    setCategorydata(category_detail.category);

    const templatecategory_detail = await template_category_list();
    setTemplateCategorydata(templatecategory_detail.template_category);
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }
  console.log(templateType, "template type ");
  console.log(themeH4FontWeight, "h4 weight  ");
  console.log(themeH3FontWeight, "h3 weight  ");
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Add Template
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}

          <form
            className={classes.form}
            // className="MuiFormControl-marginNormal"
            onSubmit={handlesubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  className={classes.txt}
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label="Name"
                  name="name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={categorydata}
                  //value={category}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option._id == value._id}
                  onChange={(event, value) => handleChangeCategory(value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Category"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  // className={classes.txt}
                  style={{ minWidth: 550 }}
                >
                  <InputLabel id="Type">Template Category</InputLabel>
                  <Select
                    // labelId="Template-Category"
                    fullWidth
                    label="Template Category"
                    value={templateCategory}
                    onChange={(e) => {
                      setTemplateCategory(e.target.value);
                    }}
                  >
                    {templateCategorydata.map((option) => {
                      return (
                        <MenuItem value={option._id}>{option.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.txt}
                  style={{ minWidth: 550 }}
                >
                  <InputLabel id="Type">Template Type</InputLabel>
                  <Select
                    labelId="Type"
                    fullWidth
                    label="Template Type"
                    value={templateType}
                    onChange={(e) => {
                      const selectedType = e.target.value;
                      setTemplateType(selectedType);
                    }}
                  >
                    {template_type.map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {file && (
                <Grid item xs={12}>
                  <>
                    <img className={classes.img} src={file} />{" "}
                  </>
                </Grid>
              )}
              <Grid item xs={12}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={fileChangedHandler}
                />

                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth
                    className={classes.txt}
                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.txt}
                  style={{ minWidth: 550 }}
                >
                  <InputLabel id="Type">Project Type</InputLabel>
                  <Select
                    labelId="Type"
                    fullWidth
                    label="Project Type"
                    value={projectType}
                    onChange={(e) => {
                      const selectedType = e.target.value;
                      setProjectType(selectedType);
                    }}
                  >
                    {project_type.map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Divider style={{ width: "100%", marginTop: "13px" }} />

              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="is_paid">Is Paid</InputLabel>
                  <Select
                    labelId="is_paid"
                    fullWidth
                    label="Is Paid"
                    value={isPaid}
                    onChange={(e) => {
                      const is_paid = e.target.value;
                      setIsPaid(is_paid);
                    }}
                  >
                    {is_Paid.map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    fullWidth
                    required
                    label="Currency"
                    name="currency"
                    disabled={isPaid === false}
                    value={isPaid === true ? currency : ""}
                    // type="number"
                    onChange={(e) => {
                      const is_paid = e.target.value;
                      setCurrency(is_paid);
                    }}
                    // InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                  required
                >
                  <InputLabel id="currency">Currency</InputLabel>
                  <Select
                    labelId="currency"
                    name="currency"
                    fullWidth
                    disabled={isPaid === false}
                    label="Currency"
                    required
                    value={isPaid === true ? currency : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCurrency(value);
                    }}
                  >
                    {currency_options.map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    fullWidth
                    required
                    disabled={isPaid === false}
                    label="Amount"
                    name="amount"
                    value={isPaid === true ? amount : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only digits, a single decimal point, and up to two decimal places
                      if (/^\d*\.?\d{0,2}$/.test(value)) {
                        setAmount(value);
                      }
                    }}
                    inputProps={{ inputMode: "decimal", pattern: "[0-9.]*" }} // Suggest numeric keypad for mobile, including decimal
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    fullWidth
                    label="Discount"
                    name="discount"
                    disabled={isPaid === false}
                    value={isPaid === true ? discount : ""}
                    error={discountError}
                    helperText={
                      discountError
                        ? `Discount cannot be greater than amount`
                        : ""
                    }
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        const newDiscount = parseFloat(e.target.value);
                        if (!isNaN(newDiscount) && newDiscount < amount) {
                          setDiscount(newDiscount);
                          setDiscountError(false);
                        } else if (newDiscount > amount) {
                          setDiscountError(true);
                          // setDiscount("");
                        } else if (newDiscount < amount) {
                          setDiscountError(false);
                          setDiscount("");
                        } else {
                          setDiscountError(false);
                          setDiscount("");
                        }
                      }
                    }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Suggest numeric keypad for mobile
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    fullWidth
                    label="VAT %"
                    name="taxPercentAge"
                    disabled={isPaid === false}
                    value={isPaid === true ? taxPercentAge : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        setTaxPercentAge(value);
                      }
                    }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Suggest numeric keypad for mobile
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Ribbon"
                    placeholder="You can enter only 15 characters"
                    name="ribbon"
                    value={isPaid === true ? ribbon : ""}
                    disabled={isPaid === false}
                    onChange={(e) => {
                      const inputValue = e.target.value.slice(0, 15);
                      setRibbon(inputValue);
                    }}
                    inputProps={{ maxLength: 15 }}
                    // disabled={ribbon.length === 14}
                    // InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid>

              <Divider style={{ width: "100%", marginTop: "13px" }} />

              <Grid item xs={12}>
                <FormControlLabel
                  fullWidth
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChecked}
                      name="checked"
                      color="primary"
                    />
                  }
                  label="Are you want to upload the folder ?"
                />
              </Grid>

              {checked == true ? (
                <>
                  <Grid item xs={12}>
                    {_folder && "Folder uploaded"}
                  </Grid>

                  <Grid item xs={12}>
                    <input
                      //  accept="image/x-png,image/jpeg"
                      hidden
                      id="contained-button-folder"
                      multiple
                      type="file"
                      directory=""
                      webkitdirectory=""
                      onChange={folderChangedHandler}
                    />

                    <label htmlFor="contained-button-folder">
                      <Button
                        component="span"
                        fullWidth
                        variant="outlined"
                        color="primary"
                      >
                        Upload Folder
                      </Button>
                    </label>

                    {projectType == 1 && HTMLFiles.length > 0 && (
                      <>
                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            className={classes.txt}
                            style={{ minWidth: 550 }}
                          >
                            <InputLabel id="Type">
                              Select Payment Page
                            </InputLabel>
                            <Select
                              labelId="Type"
                              fullWidth
                              label="Select Payment Page"
                              value={paymentPage}
                              onChange={(e) => {
                                const selectedType = e.target.value;
                                setPaymentPage(selectedType);
                              }}
                            >
                              <MenuItem hidden index value="-1">
                                Select Payment Page
                              </MenuItem>
                              {HTMLFiles.map((option, index) => {
                                return (
                                  <MenuItem value={option.replace(".html", "")}>
                                    {option.replace(".html", "")}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                ""
              )}

              <Divider style={{ width: "100%", marginBlock: "24px" }} />

              <Grid item xs={12}>
                <Button
                  size="small"
                  className={classes.default_Theme_btn}
                  variant="contained"
                  color="primary"
                  onClick={defaultTheme}
                >
                  Set Default Theme Values
                </Button>
              </Grid>
              <Divider style={{ width: "100%", marginBlock: "24px" }} />
              <Grid item xs={12}>
                <Button
                  size="small"
                  className={classes.default_Theme_btn}
                  variant="contained"
                  color="primary"
                  onClick={fetchThemeColors}
                >
                  Fetch Theme Colors From Template
                </Button>
              </Grid>
              <Grid item xs={6}>
                <p>Primary Background</p>
                <TwitterPicker
                  color={primarybackground}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setPrimaryBackground(updateColor.hex)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <p>Secondary Background</p>
                <TwitterPicker
                  color={secondarybackground}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setSecondaryBackground(updateColor.hex)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <p>Hover Background Color</p>
                <TwitterPicker
                  color={hoverBackgroundColor}
                  colors={color_array}
                  onChange={(updateColor) =>
                    sethoverBackgroundColor(updateColor.hex)
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <p>Text Heading Colour</p>
                <TwitterPicker
                  color={textHeadingcolour}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setTextHeadingColour(updateColor.hex)
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <p>Primary Text Colour</p>
                <TwitterPicker
                  color={primarytextcolour}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setPrimaryTextColour(updateColor.hex)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <p>Secondary Text Colour</p>
                <TwitterPicker
                  color={secondarytextcolour}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setSecondaryTextColour(updateColor.hex)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <p>Hover Text Color</p>
                <TwitterPicker
                  color={hoverTextColor}
                  colors={color_array}
                  onChange={(updateColor) => sethoverTextColor(updateColor.hex)}
                />
              </Grid>
              <Grid item xs={6}>
                <p>Box Shadow</p>
                <TwitterPicker
                  color={themeBoxShadow}
                  colors={color_array}
                  onChange={(updateColor) => setthemeBoxShadow(updateColor.hex)}
                />
              </Grid>
              <Grid item xs={6}>
                <p>Menu Colour</p>
                <TwitterPicker
                  color={menuColour}
                  colors={color_array}
                  onChange={(updateColor) => setMenuColour(updateColor.hex)}
                />
              </Grid>
              <Grid item xs={6}>
                <p>Menu Hover Colour</p>
                <TwitterPicker
                  color={menuHoverColour}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setMenuHoverColour(updateColor.hex)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <p>Input Text Colour</p>
                <TwitterPicker
                  color={inputTextColour}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setInputTextColour(updateColor.hex)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <p>Input Placeholder Colour</p>
                <TwitterPicker
                  color={inputPlaceholderColour}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setInputPlaceholderColour(updateColor.hex)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <p>Input Background Colour</p>
                <TwitterPicker
                  color={inputBackGroudColour}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setinputBackgroundColour(updateColor.hex)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <p>Input Border Colour</p>
                <TwitterPicker
                  color={inputBorderColour}
                  colors={color_array}
                  onChange={(updateColor) =>
                    setinputBorderColour(updateColor.hex)
                  }
                />
              </Grid>

              <Divider style={{ width: "100%", marginBlock: "24px" }} />

              <Grid item xs={12}>
                <Button
                  size="small"
                  className={classes.default_Theme_btn}
                  variant="contained"
                  color="primary"
                  onClick={fetchThemeFonts}
                >
                  Fetch Theme Fonts Vaules From Template
                </Button>
              </Grid>

              {/* <Grid item xs={4}>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="mode">Mode</InputLabel>
                  <Select
                    labelId="mode"
                    label="Mode"
                    fullWidth
                    variant="outlined"
                    value={mode}
                    onChange={(e) => {
                      const selectedMode = e.target.value;
                      setMode(selectedMode);
                    }}
                  >
                    <MenuItem value="dark">Dark</MenuItem>
                    <MenuItem value="light">Light</MenuItem>
                  </Select>
                </FormControl> */}
              {/* </Grid> */}

              <Grid item xs={12} sm={4} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="line_height">Line Height</InputLabel>
                  <Select
                    labelId="line_height"
                    fullWidth
                    variant="outlined"
                    label="line height"
                    value={themeLineHeight}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemeLineHeight(selectedSize);
                    }}
                  >
                    {line_Height.map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="font_family">Font Family</InputLabel>
                  <Select
                    labelId="font_family"
                    fullWidth
                    label="font family"
                    value={textFontFamily}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      settextFontFamily(selectedSize);
                    }}
                  >
                    {google_font_family_classes.map((font) => (
                      <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="h1_font_family">H1 Font Family </InputLabel>
                  <Select
                    labelId="h1_font_family"
                    fullWidth
                    label="h1 font family"
                    value={textH1FontFamily}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      settextH1FontFamily(selectedSize);
                    }}
                  >
                    {google_font_family_classes.map((font) => (
                      <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="h2_font_family"> H2 Font Family</InputLabel>
                  <Select
                    labelId="h2_font_family"
                    fullWidth
                    label="h2 font family"
                    value={textH2FontFamily}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      settextH2FontFamily(selectedSize);
                    }}
                  >
                    {google_font_family_classes.map((font) => (
                      <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="h3_font_family"> H3 Font Family </InputLabel>
                  <Select
                    labelId="h3_font_family"
                    fullWidth
                    label="h3 font family"
                    value={textH3FontFamily}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      settextH3FontFamily(selectedSize);
                    }}
                  >
                    {google_font_family_classes.map((font) => (
                      <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="h4_font_family"> H4 Font Family </InputLabel>
                  <Select
                    labelId="h4_font_family"
                    fullWidth
                    label="h4 font family"
                    value={textH4FontFamily}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      settextH4FontFamily(selectedSize);
                    }}
                  >
                    {google_font_family_classes.map((font) => (
                      <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Divider style={{ width: "100%", marginTop: "13px" }} />

              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="H1">H1 Font Size</InputLabel>
                  <Select
                    labelId="H1"
                    fullWidth
                    label="H1 font Size"
                    value={themeH1FontSize}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemeH1FontSize(selectedSize);
                    }}
                  >
                    {options().map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="H2">H2 Font Size</InputLabel>
                  <Select
                    labelId="H2"
                    fullWidth
                    label="H2 font Size"
                    value={themeH2FontSize}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemeH2FontSize(selectedSize);
                    }}
                  >
                    {options().map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="H3">H3 Font Size</InputLabel>
                  <Select
                    labelId="H3"
                    fullWidth
                    label="H3 font Size"
                    value={themeH3FontSize}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemeH3FontSize(selectedSize);
                    }}
                  >
                    {options().map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="H4">H4 Font Size</InputLabel>
                  <Select
                    labelId="H4"
                    fullWidth
                    label="H4 font Size"
                    value={themeH4FontSize}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemeH4FontSize(selectedSize);
                    }}
                  >
                    {options().map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="p">P Font Size</InputLabel>
                  <Select
                    labelId="p"
                    fullWidth
                    label="P font Size"
                    value={themePFontSize}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemePFontSize(selectedSize);
                    }}
                  >
                    {options().map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Divider style={{ width: "100%", marginTop: "13px" }} />
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="H1">H1 Font Weight</InputLabel>
                  <Select
                    labelId="H1"
                    fullWidth
                    label="H1 font Weight"
                    value={themeH1FontWeight}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemeH1FontWeight(selectedSize);
                    }}
                  >
                    {font_weight.map((val, index) => {
                      return <MenuItem value={val.value}>{val.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="H2">H2 Font Weight</InputLabel>
                  <Select
                    labelId="H2"
                    fullWidth
                    label="H2 font Weight"
                    value={themeH2FontWeight}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemeH2FontWeight(selectedSize);
                    }}
                  >
                    {font_weight.map((val, index) => {
                      return <MenuItem value={val.value}>{val.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="H3">H3 Font Weight</InputLabel>
                  <Select
                    labelId="H3"
                    fullWidth
                    label="H3 font Weight"
                    value={themeH3FontWeight}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemeH3FontWeight(selectedSize);
                    }}
                  >
                    {font_weight.map((val, index) => {
                      return <MenuItem value={val.value}>{val.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="H3">H4 Font Weight</InputLabel>
                  <Select
                    labelId="H4"
                    fullWidth
                    label="H4 font Weight"
                    value={themeH4FontWeight}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemeH4FontWeight(selectedSize);
                    }}
                  >
                    {font_weight.map((val, index) => {
                      return <MenuItem value={val.value}>{val.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="p">P Font Weight</InputLabel>
                  <Select
                    labelId="p"
                    fullWidth
                    label="P font Weight"
                    value={themePFontWeight}
                    onChange={(e) => {
                      const selectedSize = e.target.value;
                      setthemePFontWeight(selectedSize);
                    }}
                  >
                    {font_weight.map((val, index) => {
                      return <MenuItem value={val.value}>{val.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Divider style={{ width: "100%" }} />

              <Grid item xs={3}>
                Status :
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      checked={state.status}
                      onChange={handleChange}
                      name="status"
                    />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                Completed :
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      checked={state.completed}
                      onChange={handleChange}
                      name="completed"
                    />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                Approved :
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      checked={state.approved}
                      onChange={handleChange}
                      name="approved"
                    />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                Published :
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      checked={state.published}
                      onChange={handleChange}
                      name="published"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Template
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
