/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import { TextField } from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import { color_palette } from "../../../theme/theme";
import {
  Templates_list,
  _delete_zip_of_project,
  _making_zip_of_project,
  delete_Template,
} from "../../../DAL/templates/templates";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useSnackbar } from "notistack";
import { ButtonGroup } from "@mui/material";
import DetailDialog from "../../../components/detail_template/DetailDialog";
import moment from "moment";
import Label from "../../../components/Label";

//-------dialog-transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    // width: "10px",
    // overflow: "hidden",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

function ListTemplates(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = React.useRef(null);
  const [data, setData] = useState([]);
  console.log(data, "daattaaaatoppppp");
  const [selectedrow, setrowdata] = React.useState([]);
  console.log(selectedrow, "selectedRoww");
  const [isuser, setusers] = React.useState(false);
  const [isdata, setisdata] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [templateType, setTemplateType] = useState("active");
  const [filterTemplateValue, setFilterTemplateValue] = useState("all");
  const [allTemplateData, setAllTemplateData] = useState([]);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [detail, setDetail] = useState();
  // const [page, setPage] = React.useState(0);
  // const [totalPage, settotalpage] = React.useState();
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={{ float: "right" }}>Actions</span>,
      render: (rowData) => (
        <div className={classes.mnu}>
          <Menu
            key="left"
            direction="right"
            align="center"
            position="anchor"
            viewScroll="auto"
            menuButton={
              <MoreVertIcon style={{ marginLeft: 20 }}></MoreVertIcon>
            }
          >
            <MenuItem
              onClick={() =>
                props.history.push({
                  pathname: `/templates/editTemplates/${rowData._id}`,
                  user: rowData,
                })
              }
              className={classes.mnu}
            >
              Edit
            </MenuItem>
            {rowData.name !== "Blank Theme" && (
              <MenuItem
                onClick={() =>
                  props.history.push({
                    pathname: `/templates/edit-root-values/${rowData._id}`,
                    user: rowData,
                  })
                }
                className={classes.mnu}
              >
                Edit Root Values
              </MenuItem>
            )}
            <MenuItem
              onClick={() => handleClickOpen(rowData)}
              className={classes.mnu}
            >
              Delete
            </MenuItem>

            <MenuItem
              onClick={() => handleClickOpenPreview(rowData)}
              className={classes.mnu}
            >
              Preview
            </MenuItem>
            <MenuItem
              onClick={() => handleDownload(rowData)}
              className={classes.mnu}
            >
              Download
            </MenuItem>
            <MenuItem
              onClick={() =>
                props.history.push({
                  pathname: `/templates/${rowData._id}/pages`,
                  user: rowData,
                })
              }
              className={classes.mnu}
            >
              Pages List
            </MenuItem>
            <MenuItem
              onClick={() =>
                props.history.push({
                  pathname: `/templates/editTemplateSourceCode/${rowData._id}`,
                  user: rowData,
                })
              }
              className={classes.mnu}
            >
              Source Code
            </MenuItem>
            <MenuItem
              onClick={() =>
                props.history.push({
                  pathname: `/templates/${rowData._id}/template-assets`,
                  user: rowData,
                })
              }
              className={classes.mnu}
            >
              View Assets
            </MenuItem>
          </Menu>
        </div>
      ),
    },
    {
      title: <span className={classes.hed}>Name</span>,
      width: 150,
      field: "name",
      render: (rowData) => (
        <>
          <span
            style={{ cursor: "pointer", whiteSpace: "nowrap" }}
            onClick={() => handleDetailDialog(rowData)}
          >
            {rowData.name}
          </span>
        </>
      ),
    },

    {
      title: <span className={classes.hed}>Image</span>,
      width: 150,
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100, cursor: "pointer" }}>
            <img
              onClick={() => handleClickOpenPreview(rowData)}
              src={
                rowData.image
                  ? s3baseUrl + rowData.image
                  : s3baseUrl + default_image
              }
              style={{ height: 50, width: 75 }}
            />
          </div>
        </>
      ),
    },

    // {
    //   title: <span className={classes.hed}>Type</span>,
    //   width: 150,
    //   field: "type",
    // },
    // {
    //   title: <span className={classes.hed}>Page Count</span>,
    //   width: 150,
    //   field: "page_slug_array",
    // },
    // {
    //   title: <span className={classes.hed}>Mode</span>,
    //   width: 150,
    //   field: "mode",
    // },
    // {
    //   title: <span className={classes.hed}>Order</span>,
    //   width: 150,
    //   field: "order",
    // },
    {
      title: <span className={classes.hed}>Status</span>,

      render: (rowData) =>
        rowData.status ? (
          <>
            <div style={{ minWidth: 100 }}>
              <Radio
                checked
                style={{ color: color_palette.primary, marginLeft: -15 }}
              />
              Active
            </div>
          </>
        ) : (
          <>
            <div style={{ minWidth: 100 }}>
              <Radio
                checked={true}
                disabled
                color={color_palette.primary}
                style={{ marginLeft: -15 }}
              />
              Inactive
            </div>
          </>
        ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Usage Count</span>,
      width: 250,
      field: "use_count",
    },
    {
      title: <span className={classes.hed}>Template Type</span>,
      width: 150,
      render: (rowData) => (
        <>
          <span>
            {/* <Label
              style={{ width: "100px" }}
              color={
                rowData.app_type === 0
                  ? "success"
                  : rowData.app_type === 1
                  ? "primary"
                  : rowData.app_type === 2
                  ? "info"
                  : rowData.app_type === 3
                  ? "info"
                  : "-"
              }
            > */}
            {rowData.app_type === 0
              ? "ALL"
              : rowData.app_type === 1
              ? "Inovate"
              : rowData.app_type === 2
              ? "Magnitizer"
              : rowData.app_type === 3
              ? "Vission"
              : "-"}
            {/* </Label> */}
          </span>
        </>
      ),
    },
    {
      title: <span className={classes.hed}>Is Paid</span>,
      width: 150,
      render: (rowData) => (
        <>
          {/* <Label color={rowData.is_paid === true ? "success" : "error"}> */}{" "}
          {rowData.is_paid === true ? "YES" : "NO"}
          {/* </Label> */}
        </>
      ),
    },
    {
      title: <span className={classes.hed}>Currnecy</span>,
      width: 150,
      render: (rowData) => <>{rowData.currency}</>,
    },
    {
      title: <span className={classes.hed}>Amount</span>,
      width: 150,
      render: (rowData) => (
        <>
          {rowData.is_paid === true
            ? `${getCurrencySymbol("en-US", rowData?.currency)}${parseFloat(
                rowData.total_amount
              ).toFixed(2)}`
            : `${getCurrencySymbol("en-US", rowData?.currency)}0`}
        </>
      ),
    },
    {
      title: <span className={classes.hed}>Discount</span>,
      width: 150,
      render: (rowData) => (
        <>
          {" "}
          {rowData.is_paid === true
            ? `${getCurrencySymbol("en-US", rowData?.currency)}${
                rowData.discount
              }`
            : `${getCurrencySymbol("en-US", rowData?.currency)}0`}
        </>
      ),
    },
    {
      title: <span className={classes.hed}>Final Amount</span>,
      width: 150,
      render: (rowData) => (
        <>
          <span>
            {rowData.is_paid === true
              ? `${getCurrencySymbol("en-US", rowData?.currency)}${parseFloat(
                  rowData.amount
                ).toFixed(2)}`
              : `${getCurrencySymbol("en-US", rowData?.currency)}0`}
          </span>
        </>
      ),
    },
  ]);

  const hanldeSearchChange = async (e) => {
    // setSearch(e.target.value);
    // setData([]);
    // setisdata(false);
    // const data_search = await country_search(e.target.value);
    // console.log(data_search, "DATA SEARCH");
    // if (data_search.code == 200) {
    //   if (data_search.data.country.length != 0) {
    //     setData(data_search.data.country);
    //     settotalpage(data_search.data.total_pages);
    //     setPage(0)
    //   }
    //   setisdata(true);
    // }
  };

  const handleOpenDetailDialog = () => {
    setOpenDetailDialog(true);
  };

  const handleDetailDialog = (rowData) => {
    handleOpenDetailDialog();
    setrowdata(rowData);
  };

  const handleCloseDialog = () => {
    setOpenDetailDialog(false);
  };

  const Addtemplate = () => {
    props.history.push("/templates/addTemplates");
  };

  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };

  const handleClickOpenPreview = (rowdata) => {
    console.log(s3baseUrl, "new way to show");
    window.open(s3baseUrl + rowdata._id + "/index.html");
  };

  const StatusColor = (val) => {
    return (
      <div>
        <Label color={selectedrow.status === true ? "success" : "error"}>
          {val}
        </Label>
      </div>
    );
  };

  const handleDownload = async (template) => {
    setusers(false);
    const _id = template._id;
    console.log(_id, "mehoid");
    const resp = await _making_zip_of_project(_id);
    if (resp.code === 200) {
      await downloadZip(`${_id}`);
      setusers(true);
      setTimeout(() => {
        _delete_zip_of_project(_id);
      }, 15000);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    return resp;
  };

  const downloadZip = async (name) => {
    const zip_path = `${s3baseUrl}${name}/${name}.zip`;
    console.log(zip_path, "meho ba[ajsn");
    let download_button = document.createElement("a");
    download_button.href = zip_path;
    download_button.click();
    return true;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterTemplates = (value) => {
    setFilterTemplateValue(value);
    let list = [];
    if (value == "all") {
      if (templateType === "active") {
        list = [...allTemplateData.active];
      } else if (templateType === "inactive") {
        list = [...allTemplateData.inActive];
      } else {
        list = [...allTemplateData.all];
      }
    }
    if (value === "free") {
      if (templateType === "active") {
        list = allTemplateData.active.filter((item) => item.is_paid === false);
      } else if (templateType === "inactive") {
        list = allTemplateData.inActive.filter(
          (item) => item.is_paid === false
        );
      } else {
        list = allTemplateData.all.filter((item) => item.is_paid === false);
      }
    }
    if (value === "paid") {
      if (templateType === "active") {
        list = allTemplateData.active.filter((item) => item.is_paid);
      } else if (templateType === "inactive") {
        list = allTemplateData.inActive.filter((item) => item.is_paid);
      } else {
        list = allTemplateData.all.filter((item) => item.is_paid);
      }
    }
    setData([...list]);
  };

  const deletetemplate = async () => {
    setOpen(false);
    setData([]);
    setisdata(false);
    console.log(selectedrow._id);
    const delete_template_resp = await delete_Template(selectedrow._id);

    if (delete_template_resp.code == 200) {
      setisdata(false);
      await get_template();
    } else {
      setisdata(false);
      enqueueSnackbar(delete_template_resp.message, { variant: "error" });
      handleClose();
    }
  };

  const get_template = async () => {
    const template_list_resp = await Templates_list();
    console.log(template_list_resp.templates, "DATA");
    if (template_list_resp.code == 200) {
      let List = { active: [], inActive: [], all: [] };
      template_list_resp.templates.map((template) => {
        if (template.status === true) {
          List.active.push(template);
        } else {
          List.inActive.push(template);
        }
        List.all.push(template);
      });
      setAllTemplateData({ ...List });
      if (templateType === "active") {
        setData([...List.active]);
      } else if (templateType === "inactive") {
        setData([...List.inActive]);
      } else {
        setData([...List.all]);
      }
      console.log(data, "dtaaatttaa");
      // settotalpage(template_list_resp.data.total_pages);
      setisdata(true);
      setusers(true);
    } else {
      enqueueSnackbar(template_list_resp.message, { variant: "error" });
    }
  };

  const getCurrencySymbol = (locale, currency) => {
    if (!currency) {
      currency = "USD";
    }
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  useEffect(() => {
    get_template();
  }, []);

  useEffect(() => {
    if (selectedrow) {
      setDetail([
        {
          name: "Name",
          value: selectedrow ? selectedrow?.name : "-",
        },
        {
          name: "Order",
          value: selectedrow ? selectedrow?.order : "-",
        },
        {
          name: "Mode",
          value: selectedrow ? selectedrow?.mode : "-",
        },
        {
          name: "Published",
          value: selectedrow?.status === true ? "YES" : "NO",
        },
        {
          name: "Usage Count",
          value: selectedrow ? selectedrow?.use_count : "-",
        },
        {
          name: "Status",
          value: selectedrow.status ? (
            <>
              <div style={{ minWidth: 100 }}>
                <Radio
                  checked
                  style={{ color: color_palette.primary, marginLeft: -15 }}
                />
                Active
              </div>
            </>
          ) : (
            <>
              <div style={{ minWidth: 100 }}>
                <Radio
                  checked={true}
                  disabled
                  color={color_palette.primary}
                  style={{ marginLeft: -15 }}
                />
                Inactive
              </div>
            </>
          ),
        },
        {
          name: "Approved",
          value: selectedrow?.is_approved === true ? "YES" : "NO",
        },
        {
          name: "Is Paid",
          value: selectedrow.is_paid === true ? "YES" : "NO",
        },
        {
          name: "Currency",
          value: selectedrow ? selectedrow?.currency : "-",
        },
        {
          name: "Amount",
          value:
            selectedrow?.is_paid === true
              ? `${getCurrencySymbol("en-US", selectedrow?.currency)}${
                  selectedrow?.amount
                }`
              : `${getCurrencySymbol("en-US", selectedrow?.currency)}0`,
        },
        {
          name: "Discount",
          value:
            selectedrow?.is_paid === true
              ? `${getCurrencySymbol("en-US", selectedrow?.currency)}${
                  selectedrow?.discount
                }`
              : `${getCurrencySymbol("en-US", selectedrow?.currency)}0`,
        },
        {
          name: "Final Amount",
          value:
            selectedrow?.is_paid === true
              ? `Amount = Actual Amount (${getCurrencySymbol(
                  "en-US",
                  selectedrow?.currency
                )}${selectedrow?.amount}) - Discount (${getCurrencySymbol(
                  "en-US",
                  selectedrow?.currency
                )}${selectedrow?.discount}) = ${getCurrencySymbol(
                  "en-US",
                  selectedrow?.currency
                )}${selectedrow?.amount - selectedrow?.discount}`
              : `${getCurrencySymbol("en-US", selectedrow?.currency)}0`,
        },
        {
          name: "Ribbon",
          value: selectedrow ? selectedrow?.ribbon : "-",
        },
        {
          name: "Template Id",
          value: selectedrow ? selectedrow?._id : "-",
        },
        {
          name: "Created At",
          value: selectedrow.createdAt ? (
            moment(selectedrow.createdAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Updated At",
          value: selectedrow.updatedAt ? (
            moment(selectedrow.updatedAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
      ]);
    }
  }, [selectedrow]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      {isuser == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <Button
            onClick={Addtemplate}
            className={classes.btnadd}
            variant="contained"
            color="primary"
          >
            Add Template
          </Button>

          <div style={{ display: "flex" }}>
            <ButtonGroup
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  setTemplateType("active");
                  setData([...allTemplateData.active]);
                  console.log(data, "teemppdataaa");
                  console.log(templateType, "tempppTypeeee");
                }}
                variant={templateType == "active" ? "contained" : "outlined"}
                className="my-2"
              >
                Active
              </Button>

              <Button
                onClick={() => {
                  setTemplateType("inactive");
                  setData([...allTemplateData.inActive]);
                  console.log(data, "teemppdataaa");
                  console.log(templateType, "tempppTypeeee");
                }}
                variant={templateType == "inactive" ? "contained" : "outlined"}
                className="my-2"
              >
                In-Active
              </Button>
              <Button
                onClick={() => {
                  setTemplateType("all");
                  setData([...allTemplateData.all]);
                  console.log(data, "teemppdataaa");
                  console.log(templateType, "tempppTypeeee");
                }}
                variant={templateType == "all" ? "contained" : "outlined"}
                className="my-2"
              >
                All Templates
              </Button>
            </ButtonGroup>

            <ButtonGroup
              sx={{
                display: "flex",
                alignItems: "center",
                marginInlineStart: "36px",
              }}
            >
              <Button
                onClick={() => {
                  handleFilterTemplates("paid");
                }}
                variant={
                  filterTemplateValue == "paid" ? "contained" : "outlined"
                }
                className="my-2"
              >
                Paid-Templates
              </Button>
              <Button
                onClick={() => {
                  handleFilterTemplates("free");
                }}
                variant={
                  filterTemplateValue == "free" ? "contained" : "outlined"
                }
                className="my-2"
              >
                Free-Templates
              </Button>
              <Button
                onClick={() => {
                  handleFilterTemplates("all");
                }}
                variant={
                  filterTemplateValue == "all" ? "contained" : "outlined"
                }
                className="my-2"
              >
                All-Templates
              </Button>
            </ButtonGroup>
          </div>

          <MaterialTable
            tableRef={tableRef}
            style={{
              marginTop: "20px",
              borderRadius: "12px",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              padding: "5px",
              overflow: "hidden",
            }}
            title="Templates"
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {isdata == false ? (
                      <CircularProgress />
                    ) : (
                      <h5>No data found to display</h5>
                    )}
                  </div>
                ),
              },
            }}
            data={data}
            // actions={[
            //   {
            //     icon: () => <EditIcon />,
            //     tooltip: "Edit Country",
            //     iconProps: { style: { marginLeft:"30px" } },
            //     onClick: (event, rowData) =>
            //       props.history.push({
            //         pathname: `/templates/editTemplates/${rowData._id}`,
            //         user: rowData,
            //       }),
            //   },
            //   {
            //     icon: () => <DeleteIcon />,
            //     tooltip: "Delete Country",
            //     onClick: (event, rowData) => handleClickOpen(rowData),
            //   },
            // ]}

            options={{
              actionsColumnIndex: -1,
              search: true,
              searchFieldAlignment: "right",
              // searchFieldStyle: { marginRight: "-100px" },
              searchFieldVariant: "standard",
              pageSize: 25,
              pageSizeOptions: [25, 50, 100, 200],
              emptyRowsWhenPaging: false,
              headerStyle: {
                // backgroundColor: "black",
                color: "black",
                padding: "dense",
                fontWeight: "bold",
              },
            }}
          />
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete ${selectedrow?.name}`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={deletetemplate} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          <DetailDialog
            handleCloseDialog={handleCloseDialog}
            openDetailDialog={openDetailDialog}
            selectedrow={selectedrow}
            detail={detail}
            title="Template Detail"
          />
        </MuiThemeProvider>
      )}
    </>
  );
}
export default withRouter(ListTemplates);
